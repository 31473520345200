.dashboard-card {
	padding-left: 4px;
	padding-right: 4px;
	flex: 1;
	min-width: 350px;
	max-width: 350px;
}

@media only screen and (min-width: 1920px) {
	.dashboard-card {
		max-width: 20%;
	}
}

.dashboard-card:first-of-type {
	padding-left: 0;
}

.dashboard-card:last-of-type {
	padding-right: 0;
}

.dashboard-card--percentage-position {
	/* needed to balance second line added to summary card title */
	margin-top: -1rem;
	position: absolute;
	right: 1.5rem;
	top: calc(1.5rem + 6px);
	padding-left: 8px;
	padding-bottom: 8px;
}

.dashboard-card--percentage-text {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	transition: ease-in 0.1s;
	z-index: 3;
	width: 48px;
	height: 48px;
}

.dashboard-card--percentage-text svg {
	color: #4f38a7;
}
.dashboard-card--percentage-text span {
	flex: 1;
	text-align: center;
}

.dashboard-card--percentage-text__export:hover {
	background-color: rgba(0, 0, 0, 0.08);
	cursor: pointer;
}

.dashboard-card--percentage-bg {
	background-color: #fff;
	width: 31px;
	height: 31px;
	border-radius: 50%;
	border: 4px solid #c7bfe2;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
		0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.dashboard-card .dashboard-card--header {
	margin: -16px -24px 8px -24px;
	padding: 12px 16px;
}

.dashboard-card .dashboard-card--header span {
	opacity: 0.7;
	font-size: 1.25rem;
}

.dashboard-card .dashboard-card--header__exo {
	background: linear-gradient(
		135deg,
		rgba(255, 165, 0, 1) 0%,
		rgba(255, 187, 63, 1) 50%
	);
}

.dashboard-card .dashboard-card--header__posta {
	background: linear-gradient(
		135deg,
		rgba(0, 128, 0, 1) 0%,
		rgba(0, 166, 0, 1) 50%
	);
}

.dashboard-card .dashboard-card--header__posta,
.dashboard-card .dashboard-card--header__posta span {
	color: white;
}

.dashboard-card .dashboard-card--header__dpd {
	background: linear-gradient(
		135deg,
		rgba(221, 1, 49, 1) 0%,
		rgba(170, 0, 52, 1) 50%
	);
}

.dashboard-card .dashboard-card--header__dpd,
.dashboard-card .dashboard-card--header__dpd span {
	color: white;
}

.dashboard-card .dashboard-card--header__hozdl {
	background: black;
	background: linear-gradient(
		135deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(51, 51, 51, 1) 50%
	);
}

.dashboard-card .dashboard-card--header__hozdl,
.dashboard-card .dashboard-card--header__hozdl span {
	color: white;
}

.dashboard-card .dashboard-card--header__main {
	background: linear-gradient(135deg, #566dd9, #a327f6);
	color: white;
}
